import React, { createContext, useReducer } from 'react';
import { authenticationReducer, initialAuthState } from './authenticationReducer'
import * as ActionTypes from './actionTypes'

export const AuthenticationContext = createContext({
  authData: initialAuthState,
});

const AuthenticationContextProvider = (props) => {
  const [authData, dispatch] = useReducer(authenticationReducer, initialAuthState)

  const showFullPageLoader = (loaderContent, loaderType) => {
    dispatch({
      type: ActionTypes.SHOW_FULL_PAGE_LOADER,
      payload: {
        content: loaderContent,
        loaderType: loaderType
      }
    })
  }

  const setNotification = (message, notificationType = 'error') => {
    dispatch({
      type: ActionTypes.SET_NOTIFICATION,
      payload: {
        message: JSON.stringify(message),
        notificationType: notificationType,
        showNotification: true
      }
    })

    setTimeout(() => {
      dispatch({
        type: ActionTypes.SET_NOTIFICATION,
        payload: {
          message: null,
          notificationType: 'info',
          showNotification: false
        }
      })
    }, 3000)
  }

  const hideFullPageLoader = () => {
    dispatch({
      type: ActionTypes.HIDE_FULL_PAGE_LOADER
    })
  }


  return (
    <AuthenticationContext.Provider
      value={{
        authData,
        showFullPageLoader,
        hideFullPageLoader,
        setNotification
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationContextProvider;
import React, { createContext, useContext, useReducer } from 'react'
import { surveyReducer, initialSurveyState } from './surveyReducer'
import { AuthenticationContext } from '../authentication/authenticationContext'
import history from '../../routes/history'
import * as Survey from './surveyHelpers'
import * as ActionTypes from './actionTypes'

export const SurveyContext = createContext({
  surveyState: initialSurveyState,
  setSurvey: () => {},
  setCustomQuestion: () => {},
  setCurrentQuestion: () => {},
  submitAnswer: () => {},
  submitBooleanAnswer: () => {},
})

const SurveyContextProvider = (props) => {
  const [surveyState, dispatch] = useReducer(surveyReducer, initialSurveyState)
  const { showFullPageLoader, hideFullPageLoader } = useContext(
    AuthenticationContext
  )

  const goToNextQuestion = async ({
    nextQuestionId,
    currentQuestionOrder,
    previousQuestionId,
  }) => {
    let nextQuestion = nextQuestionId
      ? Survey.findQuestionById(surveyState, nextQuestionId)
      : Survey.findNextEnabledQuestionByQuestionOrder(
          surveyState,
          currentQuestionOrder
        )

    if (surveyState.customQuestionActive) {
      return handleSurveyComplete()
    }

    if (previousQuestionId) {
      setPreviousQuestionId(previousQuestionId)
      setSubmittedQuestionIds(previousQuestionId)
    }

    if (!nextQuestion && surveyState.customQuestion.question) {
      setPercentComplete({
        direction: 1,
        customQuestion: true,
      })
      // load the custom question in to the survey
      return dispatch({
        type: ActionTypes.SET_CUSTOM_QUESTION_ACTIVE,
        payload: {
          question: surveyState.customQuestion.question,
          possibleAnswers: surveyState.customQuestion.possibleAnswers,
        },
      })
    } else if (!nextQuestion) {
      return handleSurveyComplete()
    }

    // Keep legacy option for users to toggle leadgen of GLOBALLY via admin panel
    if (
      surveyState.speaker.disableLeadgenQuestions ||
      surveyState.configurationOptions?.disableLeadgen
    ) {
      if (nextQuestion.isLeadgenQuestion) {
        nextQuestion = Survey.findQuestionById(
          surveyState,
          nextQuestion.skipQuestionId
        )
      }
    }

    // Skip booking flow questions if option is enabled
    if (surveyState.configurationOptions?.disableBookingFlow) {
      if (nextQuestion.isBookingFlowQuestion) {
        nextQuestion = Survey.findQuestionById(
          surveyState,
          nextQuestion.skipQuestionId
        )
      }
    }

    setCurrentQuestion({ question: nextQuestion, direction: 1 })
    setPercentComplete({ question: nextQuestion, direction: 1 })
  }

  const setCustomQuestion = (data) => {
    dispatch({
      type: ActionTypes.SET_CUSTOM_QUESTION,
      payload: {
        question: data.question,
        possibleAnswers: data.possibleAnswers,
      },
    })
  }

  const setPercentComplete = ({
    question,
    direction,
    customQuestion = false,
    surveyComplete = false,
  }) => {
    const percentComplete = Survey.calcPercentComplete({
      survey: surveyState,
      question,
      direction,
      customQuestion,
      surveyComplete,
    })

    dispatch({
      type: ActionTypes.SET_PERCENT_COMPLETE,
      payload: percentComplete,
    })
  }

  const setSurvey = (surveyData) => {
    dispatch({
      type: ActionTypes.LOAD_SURVEY,
      payload: surveyData,
    })
  }

  const setEventPlannerWelcomeQuestion = (data) => {
    dispatch({
      type: ActionTypes.LOAD_EVENT_PLANNER_WELCOME_QUESTION,
      payload: data,
    })
  }

  const setCurrentQuestion = ({ question, direction }) => {
    dispatch({
      type: ActionTypes.SET_CURRENT_QUESTION,
      payload: { question, direction },
    })
  }

  const setPreviousQuestionId = (questionId) => {
    dispatch({
      type: ActionTypes.SET_PREVIOUS_QUESTION_ID,
      payload: questionId,
    })
  }

  const setSubmittedQuestionIds = (questionId) => {
    dispatch({
      type: ActionTypes.SET_SUBMITTED_QUESTION_IDS,
      payload: questionId,
    })
  }

  const updateAnswerValue = async (possibleAnswer, answerValue) => {
    dispatch({
      type: ActionTypes.ADD_ANSWER,
      payload: { ...possibleAnswer, answerValue: answerValue },
    })

    // Set surveyState for email/name recall in subsequent questions
    switch (possibleAnswer.answerType) {
      case 'EMAIL':
        updateAttendeeEmail(answerValue)
        break
      case 'NAME':
        updateAttendeeName(answerValue)
        break
      default:
        break
    }
  }

  const updateConfigurationCode = (configCode) => {
    dispatch({
      type: ActionTypes.SET_CONFIGURATION_CODE,
      payload: configCode,
    })
  }

  const updateAttendeeEmail = (email) => {
    dispatch({
      type: ActionTypes.SET_ATTENDEE_EMAIL,
      payload: email,
    })
  }

  const updateAttendeeName = (name) => {
    dispatch({
      type: ActionTypes.SET_ATTENDEE_NAME,
      payload: name,
    })
  }

  const clearSurvey = () => {
    dispatch({
      type: ActionTypes.CLEAR_SURVEY,
    })
  }

  const removePreviousQuestionId = (previousQuestionId) => {
    dispatch({
      type: ActionTypes.REMOVE_PREVIOUS_QUESTION_ID,
      payload: previousQuestionId,
    })
  }

  const setAnswers = (answers) => {
    dispatch({
      type: ActionTypes.SET_ANSWERS,
      payload: answers,
    })
  }
  const setFirstQuestionId = (id) => {
    dispatch({
      type: ActionTypes.SET_FIRST_QUESTION_ID,
      payload: id,
    })
  }
  const setCustomQuestionInactive = () => {
    dispatch({
      type: ActionTypes.SET_CUSTOM_QUESTION_INACTIVE,
    })
  }

  const submitBooleanAnswer = async (possibleAnswer) => {
    const params = Survey.makeAnswerParams(
      surveyState,
      possibleAnswer,
      possibleAnswer.content
    )
    showFullPageLoader('', 'light')

    // NOTE: This handles setting the persisted answers in state for the purpose of
    // passing the correct ids to the api when updating an answer
    const submittedAnswers = await Survey.submitAnswer(params)
    setAnswers({ answers: submittedAnswers || [] })

    // NOTE: This handles setting the answerValue for the purpose of
    // showing the user which answer they selected when using the back button
    // We need to do this because in test mode we don't get back the persisted answers
    const possibleAnswers = surveyState.possibleAnswers.filter(
      (answer) =>
        answer.questionId === possibleAnswer.questionId &&
        answer.id !== possibleAnswer.id
    )
    possibleAnswers.forEach((answer) => {
      if (
        answer.answerType === 'BOOLEAN' ||
        answer.answerType === 'MULTIPLE_CHOICE'
      ) {
        updateAnswerValue(answer, false)
      }
    })

    updateAnswerValue(possibleAnswer, true)

    hideFullPageLoader()
    await goToNextQuestion({
      nextQuestionId: possibleAnswer.nextQuestionId,
      previousQuestionId: possibleAnswer.questionId,
    })
  }

  const submitEmojiScaleAnswer = async (possibleAnswer, answerValue) => {
    const params = Survey.makeAnswerParams(
      surveyState,
      possibleAnswer,
      answerValue
    )
    showFullPageLoader('', 'light')

    const submittedAnswers = await Survey.submitAnswer(params)
    setAnswers({ answers: submittedAnswers || [] })

    updateAnswerValue(possibleAnswer, answerValue)

    hideFullPageLoader()
    await goToNextQuestion({
      nextQuestionId: possibleAnswer.nextQuestionId,
      previousQuestionId: possibleAnswer.questionId,
    })
  }

  const handleSurveyComplete = () => {
    setPercentComplete({
      direction: 1,
      surveyComplete: true,
    })
    return history.push('/survey-thank-you')
  }

  // When saving answers to a non-boolean/radio question, I need
  // to create an answer record for every possibleAnswer
  // with their appropriate value.
  // This applies to Free Flow & Multiple Choice
  const submitNonBooleanAnswer = async () => {
    const question = Survey.findCurrentQuestion(surveyState)
    const answers = Survey.findPossibleAnswersByQuestion(surveyState, question)

    const params = answers.map((answer, i) => {
      return Survey.makeAnswerParams(surveyState, answer, answer.answerValue)
    })

    showFullPageLoader('', 'light')

    const submittedAnswers = await Survey.submitAnswer(params)
    setAnswers({ answers: submittedAnswers || [] })

    hideFullPageLoader(false)

    // All of the possible answers for a given non-boolean question
    // store the same nextQuestionId
    await goToNextQuestion({
      nextQuestionId: answers[0].nextQuestionId,
      previousQuestionId: question.id,
    })
  }

  const goToPreviousQuestion = async () => {
    const previousQuestionId =
      surveyState.previousQuestionIds[
        surveyState.previousQuestionIds.length - 1
      ]
    const previousQuestion = Survey.findQuestionById(
      surveyState,
      previousQuestionId
    )

    if (previousQuestion) {
      if (surveyState.customQuestionActive) {
        setCustomQuestionInactive()
      }
      removePreviousQuestionId(previousQuestionId)
      setCurrentQuestion({ question: previousQuestion, direction: -1 })
      setPercentComplete({ question: previousQuestion, direction: -1 })
    }
  }

  return (
    <SurveyContext.Provider
      value={{
        surveyState,
        setSurvey,
        setCustomQuestion,
        updateAttendeeEmail,
        updateAttendeeName,
        clearSurvey,
        submitBooleanAnswer,
        submitNonBooleanAnswer,
        updateAnswerValue,
        submitEmojiScaleAnswer,
        setEventPlannerWelcomeQuestion,
        updateConfigurationCode,
        goToPreviousQuestion,
        goToNextQuestion,
        setFirstQuestionId,
      }}>
      {props.children}
    </SurveyContext.Provider>
  )
}

export default SurveyContextProvider
